import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';

// components pages menu
const Users = Loadable(lazy(() => import('app/views/users')));
const Stock = Loadable(lazy(() => import('app/views/stock/stock')));
const HistoryCustom = Loadable(lazy(() => import('app/views/stock/history.users')));
const Watchlist = Loadable(lazy(() => import('app/views/stock/watchlist')));
const Screening = Loadable(lazy(() => import('app/views/stock/screening')));
const Prices = Loadable(lazy(() => import('app/views/stock/normal.price')));
const Thransition = Loadable(lazy(() => import('app/views/stock/history.transaction')));
const Tpransition = Loadable(lazy(() => import('app/views/stock/package.transaction')));
const TpInvoice = Loadable(lazy(() => import('app/views/stock/package.invoices')));

// session pages
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const JwtLogin = Loadable(lazy(() => import('app/views/sessions/JwtLogin')));
const JwtRegister = Loadable(lazy(() => import('app/views/sessions/JwtRegister')));
const ForgotPassword = Loadable(lazy(() => import('app/views/sessions/ForgotPassword')));
const ResetPassword = Loadable(lazy(() => import('app/views/sessions/ResetPassword')));


// dashboard page
const Analytics = Loadable(lazy(() => import('app/views/dashboard/Analytics')));

const routes = [
  {
    element: (
      // <AuthGuard>
      <MatxLayout />
      // </AuthGuard>
    ),
    children: [
      // ...materialRoutes,
      // dashboard route
      {
        path: '/dashboard',
        element: <Watchlist />,
        // auth: authRoles.admin
      },
      {
        path: '/log_users',
        element: <Users />,
      },
      {
        path: '/stock',
        element: <Stock />,
      },
      {
        path: '/history_users',
        element: <HistoryCustom />,
      },
      {
        path: '/screening',
        element: <Screening />,
      },
      {
        path: '/stock_watchlist',
        element: <Watchlist />,
      },
      {
        path: '/harga_wajar/*',
        element: <Prices />,
      },
      {
        path: '/history.transaction',
        element: <Thransition />,
      },
      {
        path: '/package.transaction',
        element: <Tpransition />,
      },
      {
        path: '/package.invoices',
        element: <TpInvoice />,
      }
    ]
  },

  // session pages route
  { path: '/session/404', element: <NotFound /> },
  { path: '/session/signin', element: <JwtLogin /> },
  { path: '/session/signup', element: <JwtRegister /> },
  { path: '/session/forgot-password', element: <ForgotPassword /> },
  { path: '/session/reset-password', element: <ResetPassword /> },

  { path: '/', element: <Navigate to="/stock_watchlist" /> },
  { path: '*', element: <NotFound /> }
];

export default routes;
