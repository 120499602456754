import { Card, styled } from '@mui/material';

const CardRoot = styled(Card)({
  height: '100%',
  // padding: '14px 18px'
  padding: '2px 18px',
});

const CardTitle = styled('div')(({ subtitle }) => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize',
  marginBottom: !subtitle && '16px'
}));


const SimpleCard = ({ children, title, subtitle, style }) => {
  return (
    <CardRoot elevation={6} style={style}>
      <CardTitle subtitle={subtitle}>{title}</CardTitle>
      {children}
    </CardRoot>
  );
};



export default SimpleCard;
