import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
// import react, { useState, useEffect } from 'react'
// import { enqueueSnackbar } from 'notistack';


const currentYear = moment().year();

export const topBarHeight = 64
export const sideNavWidth = 200
export const navbarHeight = 60
export const sidenavCompactWidth = 80
export const containedLayoutWidth = 1200


const _handleStorage = () => {
  const token = localStorage.getItem('accessToken')
  return token ? `Bearer ${token.replace(/"/g, '')}` : null
}
// * component only
export const API_SERVE = axios.create({
  // baseURL: "http://localhost:3636", //serve local
  // baseURL: "http://192.168.253.35:3636", //serve local
  // baseURL: "https://hw.appgalaxy.my.id:3636/", //serve local
  baseURL: "https://api.hw.appgalaxy.my.id", //serve local
  timeout: 3000,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `${_handleStorage()}`
  }
});

export const forDate = val => moment(val).format('DD-MM-YYYY HH:mm:ss')
export const forDatex = val => moment(val).format('YYYY-MM-DD HH:mm:ss')
export const forDates = val => moment(val).format('DD MMM YYYY hh:mm a')
export const forDated = val => moment.utc(val).format('DD MMM YYYY hh:mm a')
export const wlDate = val => moment(val).format('DD MMM YYYY HH:mm:ss')
export const toDate = val => moment(val).format('DD MMM YYYY')
export const toMonth = val => moment(new Date()).format('MMM')
export const calculatedPages = (count, page) => Math.ceil(_.toNumber(count) / page)


export const valYear = _.range(currentYear, currentYear - 5, -1).map(year => ({ tahun: year })).reverse();

export const toPrices = (value) => {
  let val = (value / 1).toFixed().replace('.', '')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}
export const toDps = val => val ? val.replace(/(\d)(?=(\d{3})+\.)/g, '$1,') : '0';
export const toRandom = () => Math.floor(100000 + Math.random() * 900000);

export const readToken = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
export const notify = (enqueueSnackbar, txt, sts) => enqueueSnackbar(txt, {
  variant: sts,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right'
  }
});

export const _handleSortTable = (value, column, direction) => {
  return [...value].sort((a, b) => {
    const aValue = a[column];
    const bValue = b[column];

    if (aValue < bValue) {
      return direction === 'asc' ? -1 : 1;
    } else if (aValue > bValue) {
      return direction === 'asc' ? 1 : -1;
    } else {
      return 0;
    }
  });
};
const calculatePercentageChange = (valueAfter, valueBefore) => {
  const perubahanPersen = _.toNumber(valueBefore) === 0 ? 0 : ((_.toNumber(valueAfter) / _.toNumber(valueBefore)) - 1) * 100;
  return Math.round(perubahanPersen * 100) / 100; // Membulatkan ke 2 desimal
}
export const changeToPercent = (data) => {
  return data.map((item, index, array) => {
    const valueAfter = item.nominal_lapkeu;
    const valueBefore = index > 0 ? array[index - 1].nominal_lapkeu : 0;
    const perubahanPersen = calculatePercentageChange(valueAfter, valueBefore);

    return {
      tahun: item.tahun_lapkeu,
      quartal: item.quartal_lapkeu,
      percent: _.round(perubahanPersen)
    };
  });
};

export const calculateEndPercentage = (data, quartalBanding, quartalReferensi) => {
  const dataBanding = data.filter(item => item.quartal_lapkeu === quartalBanding);
  const dataReferensi = data.filter(item => item.quartal_lapkeu === quartalReferensi);

  return dataBanding.map((item, index) => {
    const valueBanding = parseFloat(item.nominal_lapkeu.toString().replace(/,/g, '')); // Konversi ke angka dan hilangkan koma
    const valueReferensi = dataReferensi[index] ? parseFloat(dataReferensi[index].nominal_lapkeu.toString().replace(/,/g, '')) : 0;

    // Rumus Excel yang benar: =IF(B10=0,0,B10/B8-1)*100
    const perubahanPersen = valueReferensi === 0 ? 0 : (valueReferensi / valueBanding - 1) * 100;
    const percent = Math.round(perubahanPersen * 100) / 100;
    return {
      code_lapkeu: item.code_lapkeu,
      tahun_lapkeu: item.tahun_lapkeu,
      percent: _.isEqual(_.round(percent), Infinity) || _.isEqual(_.round(percent), -Infinity) ? 0 : _.round(percent)
    };
  });
};

export const filterYears = (groupedData) => {
  const _data = _.groupBy(groupedData, 'tahun_lapkeu');
  const _mapYears = _.flatMap(_data, (group, year) => {
    return _.map([1, 2, 3, 4], quartal => {
      const quartalKey = `Q${quartal}`;
      const entry = _.find(group, { 'quartal_lapkeu': quartalKey });
      return {
        tahun: parseInt(year),
        quartal_lapkeu: quartalKey,
        nominal_lapkeu: entry ? entry.nominal_lapkeu : 0
      };
    });
  });
  const additionalYears = _.times(5 - Object.keys(_data).length, (index) => {
    const newYear = parseInt(Object.keys(_data)[Object.keys(_data).length - 1]) + 1 + index;
    return _.map([1, 2, 3, 4], quartal => ({
      tahun: newYear,
      quartal_lapkeu: `Q${quartal}`,
      nominal_lapkeu: '0'
    }));
  });

  return [..._mapYears, ...additionalYears.flat()];
}

export const mergeDataValue = (quartalData, dpsData) => {
  const hasilGabungan = _.keyBy([...dpsData, ...quartalData], item => `${item.tahun}-${item.quartal_lapkeu}`);
  const hasilAkhir = _.slice(_.values(hasilGabungan).map(item => ({
    ...item,
    total_dividen: _.round(item.total_dividen) || 0
  })), 0, 4);

  return hasilAkhir;
}

// export const MergeValueHistory = (valFil, history) => {
//     return _.forEach(valFil, efItem => {
//       // const historyItem = _.find(history, { tahun: efItem.tahun, quartal_lapkeu: efItem.quartal_lapkeu });
//       const historyItem = _.find(history, { tahun: efItem.tahun });
//       if (historyItem) _.assign(efItem, { nominal_lapkeu: historyItem.nominal_lapkeu.toString() });
//   });
// }

export const MergeValueHistory = (valFil, history, quart) => {
  return _.forEach(valFil, efItem => {
    const historyItem = _.find(history, { tahun: efItem.tahun, quartal: efItem.quartal_lapkeu });
    if (historyItem) {
      if (efItem.quartal_lapkeu === quart) {
        efItem.nominal_lapkeu = historyItem.nominal_lapkeu.toString();
      } else {
        // Jika quartal_lapkeu bukan 'q4', gunakan nilai asli dari efItem
        efItem.nominal_lapkeu = efItem.nominal_lapkeu.toString();
      }
    }
  });
}


export const updateDataArray = (dataArray) => {
  const originalArray = _.cloneDeep(dataArray);
  let lYear = _.last(dataArray);
  if (_.isEqual(lYear.quartal_lapkeu, 'Q4') && _.isEqual(lYear.nominal_lapkeu, '0')) return originalArray
  dataArray = _.drop(dataArray);
  const newYear = { tahun: currentYear, quartal_lapkeu: 'Q4', nominal_lapkeu: '0' }
  return _.concat(dataArray, newYear)

}

export const updateDataArrayAnnual = (dataArray, year) => {
  const originalArray = _.cloneDeep(dataArray);

  let lYear = _.last(dataArray);
  if (_.isEqual(lYear.quartal_lapkeu, 'Q4') && _.isEqual(lYear.nominal_lapkeu, '0')) return originalArray
  dataArray = _.drop(dataArray);
  const newYear = { tahun: currentYear, quartal_lapkeu: 'Q4', nominal_lapkeu: '0' }
  return _.concat(dataArray, newYear)

}
export const updateDataArrayDpv = (dataArray) => {
  const originalArray = _.cloneDeep(dataArray);
  let lYear = _.last(dataArray);
  console.log('dvps xx', lYear)
  if (_.isEqual(lYear.total_dividen, 'Q4') && _.isEqual(lYear.total_dividen, '0')) return originalArray
  dataArray = _.drop(dataArray);
  const newYear = { tahun: currentYear.toString(), total_dividen: '0' }
  return _.concat(dataArray, newYear)

}

export const replaceValueChange = (array2, array1, text) => {
  const newArray2 = _.map(array2, obj2 => {
    const _onEqual = _.find(array1, obj1 =>
      _.isEqual(obj1.column, text) &&
      _.isEqual(obj1.tahun, obj2.tahun_lapkeu) &&
      _.isEqual(obj1.quartal, obj2.quartal_lapkeu)
    )
    if (_onEqual) return { ...obj2, nominal_lapkeu: _.toString(_onEqual.to) }
    return obj2
  });
  return newArray2;
}

export const getValueEpsForText = (value, value1) => {
  const _divHistoryPro = _.size(value) > 0 ? value : value1
  const item1 = value1.slice(-1)[0]
  const _proLogLastP = _.isEqual(item1.quartal_lapkeu, _divHistoryPro[0].quartal) &&
    _.isEqual(item1.nominal_lapkeu, _divHistoryPro[0].from) ?
    _divHistoryPro[0].to : _.toNumber(item1.nominal_lapkeu)
  return _proLogLastP
}

export const currentSsb = [
  {
    value: '>',
    label: '>',
  },
  {
    value: '>=',
    label: '>=',
  },
  {
    value: '<',
    label: '<',
  },
  {
    value: '<=',
    label: '<=',
  },
  {
    value: '=',
    label: '=',
  },

];

// utils.js
export const setupZoomChangeListeners = (setZoomLevel) => {

  const handleZoomChange = () => {
    const zoom = window.devicePixelRatio;
    localStorage.setItem('accessRatio', JSON.stringify(zoom));
    setZoomLevel(zoom);
  };

  // Tambahkan event listener untuk memantau perubahan zoom
  window.addEventListener('resize', handleZoomChange);
  window.addEventListener('orientationchange', handleZoomChange);

  // Panggil handleZoomChange untuk mendapatkan nilai zoom saat komponen pertama kali dimuat
  handleZoomChange();

  // Hapus event listener pada komponen akan dilepas
  return () => {
    window.removeEventListener('resize', handleZoomChange);
    window.removeEventListener('orientationchange', handleZoomChange);
  };
};

export const getScreenInfo = () => {
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;

  const isPortrait = window.matchMedia("(orientation: portrait)").matches;
  const orientation = isPortrait ? 'Portrait' : 'Landscape';

  const pixelDensity = window.devicePixelRatio;

  return {
    screenWidth,
    screenHeight,
    orientation,
    pixelDensity
  };
};
